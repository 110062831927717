/*=========================================================================*/

/* [Header Template Styles] */

.header
{
	position: absolute;
	width: 20%;
	min-width: 300px;
	padding: 40px;
	z-index: 1000;
	
	-webkit-transform: skew(-20deg);
	-moz-transform: skew(-20deg);
	-o-transform: skew(-20deg);
	
	&:before
	{
		content: ' ';
		position: absolute;
		right: 0;
		top: 0;
		width: 100vw;
		height: 100%;
		
		background-color: $header-bg-color;
	}
	
	.contents
	{
		display: block;
		-webkit-transform: skew(20deg);
		-moz-transform: skew(20deg);
		-o-transform: skew(20deg);
	}
}

.slider-panel
{
	.header
	{
		height: 100%;
		padding-left: 0;
	}
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
	.header
	{
		width: 100%;
		position: relative;
		padding: 20px;

		-webkit-transform: skew(0deg);
		-moz-transform: skew(0deg);
		-o-transform: skew(0deg);
		
		&:before
		{
			background-color: $header-m-bg-color;
		}
		
		.contents
		{
			display: block;
			-webkit-transform: skew(0deg);
			-moz-transform: skew(0deg);
			-o-transform: skew(0deg);
		}
	}

	.slider-panel
	{
		.header
		{
			height: auto;
			padding: 20px!important;
		}
	}

}