//=========================================================================//
// [Buttons Template Styles] //
//=========================================================================//
.btn
{
	transition: all 200ms ease-in-out;
}
.btn-primary {
	background-color: $color-primary !important;
	border-color: $color-primary !important;
	color: white !important;
	
	&:not(:disabled):not(.disabled):active {
		background-color: darken($color-primary, 10) !important;
		border-color: darken($color-primary, 10) !important;
		color: white;
	}
	
	&:hover {
		background-color: white !important;
		color: black !important;
		border-color: black !important;
	}
	
	&:focus,
	&.focus,
	&:active {
		box-shadow: 0 0 0 0.2rem rgba($color-primary, 0.5) !important;
	}
}
.btn-book {
	background-color:  $color-tertiary !important;
	border-color:  $color-tertiary !important;
	color: white !important;
	
	&:not(:disabled):not(.disabled):active {
		background-color: darken( $color-tertiary, 10) !important;
		border-color: darken( $color-tertiary, 10) !important;
		color: white;
	}
	
	&:hover {
		background-color: white !important;
		color: black !important;
		border-color: black !important;
	}
	
	&:focus,
	&.focus,
	&:active {
		box-shadow: 0 0 0 0.2rem rgba($color-tertiary, 0.5) !important;
	}
}
	
.btn-success {
	background-color: $color-primary;
	border-color: darken($color-primary, 15%);
	
	&:hover
	{
		background-color: white !important;
		color: black !important;
		border-color: black !important;
	}
}

.container-book {
	position: relative;
	margin-top: 10px;
	
	.book-btn-header {
		a {
			border: solid 1px $book-btn-border;
			color: $book-btn-color;
			background: $book-btn-bg;
			padding: 8px 30px;
			font-size: 14px;
			border-radius: 5px;
			margin-bottom: 10px;
			display: block;
			
			&:hover,
			&:active,
			&:focus {
				border: solid 1px $book-btn-border;
				background: $book-btn-bg-hover;
				color: $book-btn-hover
			}
		}
	}
}

@media(max-width: 767px) {
	.container-book {
		.book-btn-header {
			position: relative;
			width: 100%;
			margin-top: 12px;
			top: 0;
			
			a {
				display: block;
			}
		}
	}
}