/*==========================================================================

	Template Elements Stylesheet
	________________________________________________________________________

	Date:		18/01/2019
	Author:		Edge Marketing Solutions

===========================================================================*/

/* [Global Variables] - http://sass-lang.com/guide */

//===========================================================================//
//                        [Font Settings]                                    //
//===========================================================================//
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500');
					 $font-default: 'Montserrat', Arial, sans-serif;
							$light: 300;
						  $regular: 400;
							 $bold: 500;

/*===========================================================================*/
/*                        [Colour Settings]                                  */
/*===========================================================================*/
                  $color-primary: #9B3338;
                $color-secondary: #589476;
                 $color-tertiary: #B4D4E5;
               $color-quaternary: rgb(109, 110, 113);
                  $color-default: rgb(109, 110, 113);

                $header-bg-color: transparentize($color-primary, 0.15);
              $header-m-bg-color: $color-primary;
              $slider-text-color: white;
		   $slider-overlay-small: $color-secondary;
           $banner-overlay-color: rgba($color-primary, 0.7);
    $feature-image-overlay-color: rgba($color-secondary, 0.8);

//===========================================================================//
//                    [Navigation Settings]                                  //
//===========================================================================//
						// Navbar Desktop
					  $nav-d-color: white;
				$nav-d-color-hover: $color-tertiary;
                       $nav-border: rgba(255, 255, 255, 0.3);

						// Navbar Desktop Dropdown
	      $nav-d-dropdown-bg-color: white;
	$nav-d-dropdown-bg-color-hover: $color-primary;
	      	 $nav-d-dropdown-color: $color-secondary;
	   $nav-d-dropdown-color-hover: $color-primary;
	  $nav-d-dropdown-color-active: $color-primary;

						// Navbar Mobile
					  $nav-m-color: white;
			   $nav-m-color-active: $color-secondary;
				   $nav-m-bg-color: transparentize($color-tertiary, 0.5);
			$nav-m-bg-color-active: $color-secondary;
		  	   $nav-m-border-color: #d4d4d4;

					$nav-bg-toggle: $color-secondary;
	   		 $nav-bg-toggle-active: $color-tertiary;
	   $nav-bg-toggle-active-color: $color-primary;
			  $nav-bg-toggle-color: white;
	   $nav-bg-toggle-color-active: $color-primary;

			 $nav-m-dropdown-color: $nav-m-color;
	  $nav-m-dropdown-color-active: $color-secondary;
				$nav-m-dropdown-bg: rgba(white, 0.5);
			 $nav-m-dropdown-li-bg: $nav-m-dropdown-bg;
		  $nav-m-dropdown-li-color: $nav-m-color-active;
	  $nav-m-dropdown-li-bg-active: $nav-m-bg-color-active;
		$nav-m-dropdown-box-shadow: inset 0 0 10px 2px #C7C7C7;
				  $nav-m-li-border: 1px solid rgba(white, 0.5);

						$logo-text: $color-secondary;
				  $logo-text-hover: $color-default;
				 $logo-text-mobile: $color-primary;
		   $logo-text-hover-mobile: $color-default;

			  $centre-select-color: $color-primary;
		$centre-select-color-hover: white;
		   $centre-select-bg-color: transparent;
	 $centre-select-bg-color-hover: $color-primary;
			 $centre-select-border: solid 1px transparentize($color-primary, 0.75);
	   $centre-select-border-hover: solid 1px transparentize($color-primary, 0.75);

//===========================================================================//
//                        [Slider Settings]                                  //
//===========================================================================//
				   $slider-overlay: white;
			   $slider-overlay-bar: rgba(white, 0.5);
	   $slider-text-shadow-opacity: 0 0 3px rgba(0, 0, 0, 0.25);
					   $gradient-1: transparent;
					   $gradient-2: transparent;

/*===========================================================================*/
/*                  [Book Button Settings]                                   */
/*===========================================================================*/
                 $book-btn-color: white;
                 $book-btn-hover: white;
                    $book-btn-bg: $color-secondary;
              $book-btn-bg-hover: $color-primary;
                $book-btn-border: $color-secondary;

/*===========================================================================*/
/*                  [Feature Navigation Settings]                            */
/*===========================================================================*/
               $feature-nav-bg-1: $color-primary;
               $feature-nav-bg-2: $color-secondary;
               $feature-nav-bg-3: $color-tertiary;
            $feature-nav-color-1: white;
            $feature-nav-color-2: white;
            $feature-nav-color-3: $color-primary;
              $feature-nav-hover: $color-default;
        $feature-nav-hover-color: white;

/*===========================================================================*/
/*                      [Download Settings]                                  */
/*===========================================================================*/
				      $download-bg: $color-primary;
			$download-bg-secondary: $color-tertiary;
				   $download-color: white;
				   $download-title: $color-tertiary;
				  $download-btn-bg: $color-secondary;
			   $download-btn-color: white;
			$download-btn-bg-hover: white;
		 $download-btn-color-hover: $color-primary;

/*===========================================================================*/
/*                        [Footer Settings]                                  */
/*===========================================================================*/
					  $footer-bg: $color-primary;
				   $footer-color: rgba(white, 0.66);
			     $footer-a-color: $footer-color;
			     $footer-a-hover: rgba(white, 1);
				     $row-social: $footer-color;
			   $row-social-hover: $footer-a-hover;
					  $footer-h5: $color-tertiary;

/*=========================================================================*/

/* [Global Template Styles] */

body
{
	color: $color-default;
	font-size: 16px;
	line-height: 1.8em;
	font-family: $font-default;
	font-weight: $regular;
}

h1
{
	margin: 0 0 20px 0;
	color: $color-primary;
	font-size: 38px;
	font-weight: $regular;
	
	&.logo-text {
		a {
			color: $logo-text;
			
			&:hover,
			&:active,
			&:focus {
				color: $logo-text-hover;
			}
		}
	}
}

h2
{
	margin: 20px 0;
	color: $color-primary;
	font-size: 28px;
	font-weight: $regular;
}

h3
{
	margin: 20px 0;
	color: $color-primary;
	font-size: 28px;
	font-weight: $regular;
}

h4
{
	margin: 20px 0 0 0;
	color: $color-primary;
	font-weight: $regular;
	font-size: 18px;
}

h5
{
	font-weight: bold;
	text-transform: uppercase;
	font-size: 13px;
}

a
{
	transition: all 150ms ease-in-out;

	color: $color-primary;
	
	&:hover,
	&:active,
	&:focus {
		color: lighten($color-primary, 20%);
		text-decoration: none;
	}
}

img
{
	max-width: 100%;
}

img.svg-responsive
{
	width: 100% \9;
}

/*OPTIONAL FULL WIDTH NAVIGATION - Add nav-justified to <ul class="nav navbar-nav">*/
.navbar-nav.nav-justified > li {
	float: none!important;
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
	body
	{
		font-size: 14px;
		line-height: 1.6em;
	}

	h1
	{
		font-size: 30px;
		
		&.logo-text a {
			color: $logo-text-mobile;
			text-align: center;
			
			&:hover,
			&:active,
			&:focus {
				color: $logo-text-hover-mobile;
			}
		}
	}

	h2
	{
		font-size: 24px;
	}

	h3
	{
		font-size: 18px;
	}

	h4
	{
		font-size: 15px;
	}
}

@import "./partials/template-2/header";

@import "./partials/template-2/navigation";

@import "./partials/template-2/slider";

@import "./partials/template-2/buttons";

@import "./partials/chiro/download";

@import "./partials/chiro/footer";

@import "./partials/chiro/overrides";


/* Hover Click Fix */
//@media (min-width: 768px) {
//  .dropdown:hover .dropdown-menu {
//    display: block;
//    margin-top: 0;
//  }
//}

/*=========================================================================*/

/* [Page Template Styles] */

.page
{
	width: 100%;
}

.page-subpage
{
	padding-bottom: 40px;
	width: 80%;
	float: right;
}

.homepage-wrapper
{
	width: 100%;
	display: flex;
}

.homepage-wrapper .content
{
	flex-basis: 60%;
}

.homepage-wrapper .feature-image
{
	flex-basis: 40%;

	background-size: cover;
	background-position: center top;
}

.feature-buttons
{
	width: 100%;
	display: table;
}

.feature-buttons .item
{
	width: 33.33333%;
	padding: 40px 15px;
	display: table-cell;
	vertical-align: middle;

	font-weight: 400;
	font-size: 28px;
	text-align: center;
}

.feature-buttons .item small
{
	font-size: 18px;
	font-weight: 200;
}

.feature-buttons .item span
{
	display: block;
	font-size: 50px;
	padding: 0 0 16px 0;
}

.feature-buttons .item.item-style-1
{
	background-color: $feature-nav-bg-1;

	color: $feature-nav-color-1;
}

.feature-buttons .item.item-style-2
{
	background-color: $feature-nav-bg-2;

	color: $feature-nav-color-2;
}

.feature-buttons .item.item-style-3
{
	background-color: $feature-nav-bg-3;

	color: $feature-nav-color-3;
}

.feature-buttons .item:hover
{
	background-color: $feature-nav-hover;

	text-decoration: none;
	color: $feature-nav-hover-color;
}

.page-banner
{
	width: 100%;
	margin: 0 0 40px 0;
}

.download {
	width: 100%;
	margin: 0 0 20px 0;
	float: left;
}

.download .icon {
	width: 100%;
	float: left;
	height: 120px;
	background-image: url(../images/template-1/ebook-icon.jpg);
	background-repeat: no-repeat;
	background-size: cover;
}

.download .details {
	width: 100%;
	padding: 15px;
	height: 100%;
	float: left;
	background-color: $download-bg;
	color: $download-color;
	text-align: center;
}

.download .details h3 {
	margin: 0 0 5px;
	padding: 0;
	color: $download-title;
	line-height: 1.0em;
	font-size: 16px;
	font-weight: bold;
}

.download .details a.btn {
	margin: 8px 0 0 0;
	background-color: $download-btn-bg;
	color: $download-btn-color;
}

.download .details a.btn:hover {
	background-color: $download-btn-bg-hover;
	color: $download-btn-color-hover;
}

.infosheets
{
	padding: 15px;
	width: 100%;
	float: left;

	background-color: rgba(175, 175, 175,0.2);
}

.contact-table {

}
.contact-phone td:nth-child(2) {

}
.contact-phone-mobile {

}
.contact-fax {

}
.contact-email {

}
.contact-address {

}

/* Large desktop */
@media (min-width: 1200px) {
	.homepage-wrapper .content
	{
		padding: 100px;
	}

	.download .details h3
	{
		font-size: 20px;
	}

	.page-subpage
	{
		padding-right: 50px;
	}
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
	.homepage-wrapper .content
	{
		padding: 80px;
	}

	.feature-buttons .item
	{
		padding: 40px 10px;

		font-size: 24px;
	}

	.feature-buttons .item small
	{
		font-size: 16px;
	}

	.page-subpage
	{
		width: 75%;
	}
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
	.homepage-wrapper .content
	{
		padding: 40px;
	}

	.feature-buttons .item
	{
		padding: 30px 10px;

		font-size: 20px;
		line-height: 1.0em;
	}

	.feature-buttons .item small
	{
		font-size: 14px;
	}

	.page-subpage
	{
		width: 65%;
	}

	.download
	{
		height: auto;
	}

	.download .icon
	{
		width: 100%;
		height: 100px;
	}

	.download .details
	{
		width: 100%;
		height: auto;

		text-align: center;
	}

	.download .details h3
	{
		font-size: 17px;
		font-weight: bold;
	}
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
	.homepage-wrapper
	{
		display: block;
	}

	.homepage-wrapper .content
	{
		width: 100%;
		padding: 30px;
	}

	.homepage-wrapper .feature-image
	{
		width: 100%;
		height: 400px;
	}

	.feature-buttons
	{
		display: block;
	}

	.feature-buttons .item
	{
		padding: 25px 10px;
		display: block;
		width: 100%;

		font-size: 22px;
		line-height: 1.0em;
	}

	.feature-buttons .item small
	{
		font-size: 14px;
	}

	.page-banner
	{
		margin: 0;
	}

	.page-subpage
	{
		width: 100%;
		padding: 30px 20px;
	}
}