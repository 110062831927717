//=========================================================================//
// [Footer Template Styles] //
//=========================================================================//
.footer {
	width: 100%;
	padding: 40px 0;
	margin: 0;
	background-color: $footer-bg;
	color: $footer-color;
	font-size: 12px;
	
	h5 {
		margin-top: 0;
		color: $footer-h5
	}
	
	a {
		color: $footer-a-color;
		
		&:hover {
			color: $footer-a-hover;
			text-decoration: none;
		}
		
		.btn {
			margin: 0 0 30px 0;
			background-color: rgba(0, 0, 0, 0.4);
			color: white;
			
			&:hover {
				background-color: white;
				color: rgba(0, 0, 0, 0.8);
			}
		}
	}
	
	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		
		> li {
			padding: 5px 0;
			border-top: 1px solid rgba(0, 0, 0, 0.1);
		}
	}
	
	.row-social {
		margin-bottom: 10px;
		
		font-size: 30px;
		text-align: right;
		
		a {
			color: $row-social;
			
			&:hover {
				color: $row-social-hover;
			}
		}
	}
}


.logo-chiropractic-iq,
.logo-caa {
	transition: all 150ms ease-in-out;
	width: 100%;
	opacity: 0.5;
	float: right;
	
	&:hover {
		opacity: 1;
	}
}

.logo-caa {
	width: 100%;
	margin-bottom: 10px;
}

// Landscape phone to portrait tablet //
@media (max-width: 767px) {
	.footer {
		text-align: center;
		
		h5 {
			margin: 30px 0 10px 0;
		}
	}
	.logo-chiropractic-iq {
		margin: 20px auto;
		width: 50%;
		float: none;
	}
	.logo-caa {
		width: 50%;
		margin: 40px auto 0 auto;
		float: none;
	}
}