//=========================================================================//
// [Download Template Styles] //
//=========================================================================//
.download {
	width: 100%;
	margin: 0 0 20px 0;
	float: left;
	
	.icon {
		width: 100%;
		float: left;
		height: 120px;
		background-image: url('../images/ebook-icon.jpg');
		background-repeat: no-repeat;
		background-size: cover;
	}
	
	.details {
		width: 100%;
		padding: 15px;
		height: 100%;
		float: left;
		background-color: $download-bg;
		color: $download-color;
		text-align: center;
		
		h3 {
			margin: 0 0 5px;
			padding: 0;
			color: $download-title;
			line-height: 1.0em;
			font-size: 16px;
			font-weight: bold;
		}
		
        a {
            &.btn {
                margin: 8px 0 0 0;
                background-color: $download-btn-bg;
                color: $download-btn-color;
                
                &:hover {
                    background-color: $download-btn-bg-hover;
                    color: $download-btn-color-hover;
                }
            }
        }
		
		&.secondary {
			background-color: $download-bg-secondary!important;
			
			a {
				&.btn {
					
					&:hover {
						background-color: darken($download-bg-secondary, 20%);
						color: $download-btn-color-hover;
					}
				}
			}
		}
	}
}

// Large desktop //
@media (min-width: 1200px) {
	.download .details h3 {
		font-size: 20px;
	}
}

// Portrait tablet to landscape and desktop //
@media (min-width: 768px) and (max-width: 991px) {
	.download {
		height: auto;
		
		.icon {
			width: 100%;
			height: 100px;
		}
		
		.details {
			width: 100%;
			height: auto;
			text-align: center;
			
			h3 {
				font-size: 17px;
				font-weight: bold;
			}
		}
	}
}
