/*==========================================================================

	Template Elements Stylesheet
	________________________________________________________________________

	Date:		18/01/2019
	Author:		Edge Marketing Solutions

===========================================================================*/
/* [Global Variables] - http://sass-lang.com/guide */
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500");
/*===========================================================================*/
/*                        [Colour Settings]                                  */
/*===========================================================================*/
/*===========================================================================*/
/*                  [Book Button Settings]                                   */
/*===========================================================================*/
/*===========================================================================*/
/*                  [Feature Navigation Settings]                            */
/*===========================================================================*/
/*===========================================================================*/
/*                      [Download Settings]                                  */
/*===========================================================================*/
/*===========================================================================*/
/*                        [Footer Settings]                                  */
/*===========================================================================*/
/*=========================================================================*/
/* [Global Template Styles] */
body {
  color: #6d6e71;
  font-size: 16px;
  line-height: 1.8em;
  font-family: "Montserrat", Arial, sans-serif;
  font-weight: 400;
}

h1 {
  margin: 0 0 20px 0;
  color: #9B3338;
  font-size: 38px;
  font-weight: 400;
}

h1.logo-text a {
  color: #589476;
}

h1.logo-text a:hover, h1.logo-text a:active, h1.logo-text a:focus {
  color: #6d6e71;
}

h2 {
  margin: 20px 0;
  color: #9B3338;
  font-size: 28px;
  font-weight: 400;
}

h3 {
  margin: 20px 0;
  color: #9B3338;
  font-size: 28px;
  font-weight: 400;
}

h4 {
  margin: 20px 0 0 0;
  color: #9B3338;
  font-weight: 400;
  font-size: 18px;
}

h5 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
}

a {
  transition: all 150ms ease-in-out;
  color: #9B3338;
}

a:hover, a:active, a:focus {
  color: #cd676c;
  text-decoration: none;
}

img {
  max-width: 100%;
}

img.svg-responsive {
  width: 100% \9;
}

/*OPTIONAL FULL WIDTH NAVIGATION - Add nav-justified to <ul class="nav navbar-nav">*/
.navbar-nav.nav-justified > li {
  float: none !important;
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  body {
    font-size: 14px;
    line-height: 1.6em;
  }
  h1 {
    font-size: 30px;
  }
  h1.logo-text a {
    color: #9B3338;
    text-align: center;
  }
  h1.logo-text a:hover, h1.logo-text a:active, h1.logo-text a:focus {
    color: #6d6e71;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 15px;
  }
}

/*=========================================================================*/
/* [Header Template Styles] */
.header {
  position: absolute;
  width: 20%;
  min-width: 300px;
  padding: 40px;
  z-index: 1000;
  -webkit-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -o-transform: skew(-20deg);
}

.header:before {
  content: ' ';
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(155, 51, 56, 0.85);
}

.header .contents {
  display: block;
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
}

.slider-panel .header {
  height: 100%;
  padding-left: 0;
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .header {
    width: 100%;
    position: relative;
    padding: 20px;
    -webkit-transform: skew(0deg);
    -moz-transform: skew(0deg);
    -o-transform: skew(0deg);
  }
  .header:before {
    background-color: #9B3338;
  }
  .header .contents {
    display: block;
    -webkit-transform: skew(0deg);
    -moz-transform: skew(0deg);
    -o-transform: skew(0deg);
  }
  .slider-panel .header {
    height: auto;
    padding: 20px !important;
  }
}

.navbar-default {
  margin: 20px 0 0 0;
  padding: 20px 0 0 0;
  background-color: transparent;
  background-image: none;
  border: none;
  box-shadow: none;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.navbar-default .navbar-toggle {
  width: 100%;
  margin: 0;
  padding: 15px;
  background-color: #589476;
  border: none;
  font-size: 16px;
  color: white;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
  background-color: #B4D4E5;
  color: #9B3338;
}

.navbar-default .navbar-nav {
  float: none;
  margin-top: 10px;
}

.navbar-default .navbar-nav > li {
  float: none;
}

.navbar-default .navbar-nav > li > a {
  padding: 12px 0;
  text-shadow: none;
  color: white;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  color: #B4D4E5;
}

.navbar-default .navbar-nav > li.active > a,
.navbar-default .navbar-nav > li.active > a:hover,
.navbar-default .navbar-nav > li.active > a:focus {
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  color: #B4D4E5;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  box-shadow: none;
  background-color: transparent;
  background-image: none;
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .navbar-default {
    margin: 15px 0 0 0;
    padding: 15px 0 0 0;
  }
  .navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border: none;
    background-color: rgba(180, 212, 229, 0.5);
  }
  .navbar-default .navbar-nav > li > a {
    text-align: center;
    font-size: 16px;
    color: white;
  }
  .navbar-default .navbar-nav .open > a {
    background-color: rgba(0, 0, 0, 0.5);
    color: #589476;
  }
  .navbar-default .navbar-nav .open > a:hover, .navbar-default .navbar-nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.5);
    color: #589476;
  }
  .navbar-default .navbar-nav .open .dropdown-menu {
    background-color: rgba(0, 0, 0, 0.3);
    font-weight: 300;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    text-align: center;
    color: #589476;
  }
}

.slider-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
}

.slider-panel {
  position: relative;
  width: 100%;
  height: 80vh;
  min-height: 680px;
}

.slide-content {
  color: white;
}

.slide-content h2 {
  position: relative;
  margin: 60vh 0 30px 0;
  font-size: 40px;
  font-weight: 700;
  color: white;
  text-align: center;
  text-shadow: 1px 1px 1px #333333;
}

.slide-content h2 small {
  color: #589476;
  font-size: 36px;
  font-weight: 300;
}

.slide-content h3 {
  margin: 0 0 30px 0;
}

.slide-content a.btn {
  padding: 10px 20px;
}

.slide-content a.btn-default {
  background-color: white;
}

.slide-content a.btn-info {
  background-color: #00aeef;
  color: white;
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .slider-wrapper {
    position: relative;
    width: 100%;
    height: 170px;
  }
  .slider-panel {
    position: relative;
    width: 100%;
    height: 60vh !important;
    min-height: 0;
    background: #9b3338;
  }
}

@media (max-width: 767px) and (max-height: 1080px) {
  .slider-wrapper {
    height: 170px;
  }
  .slider-panel {
    height: 500px !important;
  }
}

.btn {
  transition: all 200ms ease-in-out;
}

.btn-primary {
  background-color: #9B3338 !important;
  border-color: #9B3338 !important;
  color: white !important;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #75262a !important;
  border-color: #75262a !important;
  color: white;
}

.btn-primary:hover {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.btn-primary:focus, .btn-primary.focus, .btn-primary:active {
  box-shadow: 0 0 0 0.2rem rgba(155, 51, 56, 0.5) !important;
}

.btn-book {
  background-color: #B4D4E5 !important;
  border-color: #B4D4E5 !important;
  color: white !important;
}

.btn-book:not(:disabled):not(.disabled):active {
  background-color: #8ebed8 !important;
  border-color: #8ebed8 !important;
  color: white;
}

.btn-book:hover {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.btn-book:focus, .btn-book.focus, .btn-book:active {
  box-shadow: 0 0 0 0.2rem rgba(180, 212, 229, 0.5) !important;
}

.btn-success {
  background-color: #9B3338;
  border-color: #612023;
}

.btn-success:hover {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.container-book {
  position: relative;
  margin-top: 10px;
}

.container-book .book-btn-header a {
  border: solid 1px #589476;
  color: white;
  background: #589476;
  padding: 8px 30px;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: block;
}

.container-book .book-btn-header a:hover, .container-book .book-btn-header a:active, .container-book .book-btn-header a:focus {
  border: solid 1px #589476;
  background: #9B3338;
  color: white;
}

@media (max-width: 767px) {
  .container-book .book-btn-header {
    position: relative;
    width: 100%;
    margin-top: 12px;
    top: 0;
  }
  .container-book .book-btn-header a {
    display: block;
  }
}

.download {
  width: 100%;
  margin: 0 0 20px 0;
  float: left;
}

.download .icon {
  width: 100%;
  float: left;
  height: 120px;
  background-image: url("../images/ebook-icon.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.download .details {
  width: 100%;
  padding: 15px;
  height: 100%;
  float: left;
  background-color: #9B3338;
  color: white;
  text-align: center;
}

.download .details h3 {
  margin: 0 0 5px;
  padding: 0;
  color: #B4D4E5;
  line-height: 1.0em;
  font-size: 16px;
  font-weight: bold;
}

.download .details a.btn {
  margin: 8px 0 0 0;
  background-color: #589476;
  color: white;
}

.download .details a.btn:hover {
  background-color: white;
  color: #9B3338;
}

.download .details.secondary {
  background-color: #B4D4E5 !important;
}

.download .details.secondary a.btn:hover {
  background-color: #68a9cb;
  color: #9B3338;
}

@media (min-width: 1200px) {
  .download .details h3 {
    font-size: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .download {
    height: auto;
  }
  .download .icon {
    width: 100%;
    height: 100px;
  }
  .download .details {
    width: 100%;
    height: auto;
    text-align: center;
  }
  .download .details h3 {
    font-size: 17px;
    font-weight: bold;
  }
}

.footer {
  width: 100%;
  padding: 40px 0;
  margin: 0;
  background-color: #9B3338;
  color: rgba(255, 255, 255, 0.66);
  font-size: 12px;
}

.footer h5 {
  margin-top: 0;
  color: #B4D4E5;
}

.footer a {
  color: rgba(255, 255, 255, 0.66);
}

.footer a:hover {
  color: white;
  text-decoration: none;
}

.footer a .btn {
  margin: 0 0 30px 0;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}

.footer a .btn:hover {
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
}

.footer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer ul > li {
  padding: 5px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer .row-social {
  margin-bottom: 10px;
  font-size: 30px;
  text-align: right;
}

.footer .row-social a {
  color: rgba(255, 255, 255, 0.66);
}

.footer .row-social a:hover {
  color: white;
}

.logo-chiropractic-iq,
.logo-caa {
  transition: all 150ms ease-in-out;
  width: 100%;
  opacity: 0.5;
  float: right;
}

.logo-chiropractic-iq:hover,
.logo-caa:hover {
  opacity: 1;
}

.logo-caa {
  width: 100%;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
  .footer h5 {
    margin: 30px 0 10px 0;
  }
  .logo-chiropractic-iq {
    margin: 20px auto;
    width: 50%;
    float: none;
  }
  .logo-caa {
    width: 50%;
    margin: 40px auto 0 auto;
    float: none;
  }
}

#media-query-detector {
  display: none;
  width: 0;
}

@media (min-width: 768px) {
  #media-query-detector {
    width: 768px;
  }
}

@media (min-width: 992px) {
  #media-query-detector {
    width: 992px;
  }
}

@media (min-width: 1200px) {
  #media-query-detector {
    width: 1200px;
  }
}

.row-fix-clear-both {
  clear: both;
}

table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
  content: " ";
  display: table;
  clear: both;
}

table.table-as-row > tbody > tr {
  height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
  display: block;
  width: auto;
}

table.table-as-row > tbody > tr {
  display: block;
  width: auto;
  margin-right: -15px;
  margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
  display: block;
  height: auto !important;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  table.table-as-row > tbody > tr > td[class*=col-lg-] {
    float: left;
  }
}

@media (min-width: 992px) {
  table.table-as-row > tbody > tr > td[class*=col-md-] {
    float: left;
  }
}

@media (min-width: 768px) {
  table.table-as-row > tbody > tr > td[class*=col-sm-] {
    float: left;
  }
}

table.table-as-row > tbody > tr > td[class*=col-xs-] {
  float: left;
}

/* Hover Click Fix */
/*=========================================================================*/
/* [Page Template Styles] */
.page {
  width: 100%;
}

.page-subpage {
  padding-bottom: 40px;
  width: 80%;
  float: right;
}

.homepage-wrapper {
  width: 100%;
  display: flex;
}

.homepage-wrapper .content {
  flex-basis: 60%;
}

.homepage-wrapper .feature-image {
  flex-basis: 40%;
  background-size: cover;
  background-position: center top;
}

.feature-buttons {
  width: 100%;
  display: table;
}

.feature-buttons .item {
  width: 33.33333%;
  padding: 40px 15px;
  display: table-cell;
  vertical-align: middle;
  font-weight: 400;
  font-size: 28px;
  text-align: center;
}

.feature-buttons .item small {
  font-size: 18px;
  font-weight: 200;
}

.feature-buttons .item span {
  display: block;
  font-size: 50px;
  padding: 0 0 16px 0;
}

.feature-buttons .item.item-style-1 {
  background-color: #9B3338;
  color: white;
}

.feature-buttons .item.item-style-2 {
  background-color: #589476;
  color: white;
}

.feature-buttons .item.item-style-3 {
  background-color: #B4D4E5;
  color: #9B3338;
}

.feature-buttons .item:hover {
  background-color: #6d6e71;
  text-decoration: none;
  color: white;
}

.page-banner {
  width: 100%;
  margin: 0 0 40px 0;
}

.download {
  width: 100%;
  margin: 0 0 20px 0;
  float: left;
}

.download .icon {
  width: 100%;
  float: left;
  height: 120px;
  background-image: url(../images/template-1/ebook-icon.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.download .details {
  width: 100%;
  padding: 15px;
  height: 100%;
  float: left;
  background-color: #9B3338;
  color: white;
  text-align: center;
}

.download .details h3 {
  margin: 0 0 5px;
  padding: 0;
  color: #B4D4E5;
  line-height: 1.0em;
  font-size: 16px;
  font-weight: bold;
}

.download .details a.btn {
  margin: 8px 0 0 0;
  background-color: #589476;
  color: white;
}

.download .details a.btn:hover {
  background-color: white;
  color: #9B3338;
}

.infosheets {
  padding: 15px;
  width: 100%;
  float: left;
  background-color: rgba(175, 175, 175, 0.2);
}

/* Large desktop */
@media (min-width: 1200px) {
  .homepage-wrapper .content {
    padding: 100px;
  }
  .download .details h3 {
    font-size: 20px;
  }
  .page-subpage {
    padding-right: 50px;
  }
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .homepage-wrapper .content {
    padding: 80px;
  }
  .feature-buttons .item {
    padding: 40px 10px;
    font-size: 24px;
  }
  .feature-buttons .item small {
    font-size: 16px;
  }
  .page-subpage {
    width: 75%;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .homepage-wrapper .content {
    padding: 40px;
  }
  .feature-buttons .item {
    padding: 30px 10px;
    font-size: 20px;
    line-height: 1.0em;
  }
  .feature-buttons .item small {
    font-size: 14px;
  }
  .page-subpage {
    width: 65%;
  }
  .download {
    height: auto;
  }
  .download .icon {
    width: 100%;
    height: 100px;
  }
  .download .details {
    width: 100%;
    height: auto;
    text-align: center;
  }
  .download .details h3 {
    font-size: 17px;
    font-weight: bold;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .homepage-wrapper {
    display: block;
  }
  .homepage-wrapper .content {
    width: 100%;
    padding: 30px;
  }
  .homepage-wrapper .feature-image {
    width: 100%;
    height: 400px;
  }
  .feature-buttons {
    display: block;
  }
  .feature-buttons .item {
    padding: 25px 10px;
    display: block;
    width: 100%;
    font-size: 22px;
    line-height: 1.0em;
  }
  .feature-buttons .item small {
    font-size: 14px;
  }
  .page-banner {
    margin: 0;
  }
  .page-subpage {
    width: 100%;
    padding: 30px 20px;
  }
}
