//=========================================================================//
// [Navigation Template Styles] //
//=========================================================================//

.navbar-default
{
	margin: 20px 0 0 0;
	padding: 20px 0 0 0;

	background-color: transparent;
	background-image: none;
	border: none;
	box-shadow: none;
	border-top: 1px solid $nav-border;
	
	.navbar-toggle
	{
		width: 100%;
		margin: 0;
		padding: 15px;
		
		background-color: $nav-bg-toggle;
		border: none;
		
		font-size: 16px;
		color: $nav-bg-toggle-color;
		
		&:hover,
		&:focus
		{
			background-color: $nav-bg-toggle-active;
			color: $nav-bg-toggle-color-active;
		}
	}
	
	.navbar-nav
	{
		float: none;
		margin-top: 10px;
		
		> li
		{
			float: none;
			
			> a
			{
				padding: 12px 0;
				
				text-shadow: none;
				color: $nav-d-color;
				
				&:hover,
				&:focus
				{
					color: $nav-d-color-hover;
				}
			}
			
			&.active > a,
			&.active > a:hover,
			&.active > a:focus
			{
				box-shadow: none;
				background-color: transparent;
				background-image: none;
			
				color: $nav-d-color-hover;
			}
		}
		
		> .open > a,
		> .open > a:hover,
		> .open > a:focus
		{
			box-shadow: none;
			background-color: transparent;
			background-image: none;
		}
	}
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
	.navbar-default
	{
		margin: 15px 0 0 0;
		padding: 15px 0 0 0;
		
		.navbar-collapse,
		.navbar-form
		{
			border: none;
			background-color: $nav-m-bg-color;
		}
		
		.navbar-nav
		{
			
			> li > a
			{
				text-align: center;
				font-size: 16px;
				color: $nav-m-color;
			}
			
			.open
			{
				> a
				{
					background-color: rgba(0, 0, 0, 0.5);
					
					color: $nav-m-color-active;
					
					&:hover,
					&:focus
					{
						background-color: rgba(0, 0, 0, 0.5);
						
						color: $nav-m-color-active;
					}
				}
				
				.dropdown-menu
				{
					background-color: rgba(0, 0, 0, 0.3);
					font-weight: 300;
					
					> li > a
					{
						text-align: center;
						color: $nav-m-color-active;
					}
				}
			}
		}
	}
}