//=========================================================================//
// [Slick Slider Styles] //
//=========================================================================//

.slider-wrapper {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 0;
}

.slider-panel
{
	position: relative;
	width: 100%;
	height: 80vh;
	min-height: 680px;
}

.slide-content {
	color: white;
}

.slide-content h2 {
	position: relative;
	margin: 60vh 0 30px 0;
	font-size: 40px;
	font-weight: 700;
	color: $slider-text-color;
	text-align: center;
	text-shadow: 1px 1px 1px #333333;
	
	small
	{
		color: $slider-overlay-small;
		font-size: 36px;
		font-weight: 300;
	}
}

.slide-content h3 {
	margin: 0 0 30px 0;
}

.slide-content a.btn {
	padding: 10px 20px;
}

.slide-content a.btn-default {
	background-color: white;
}

.slide-content a.btn-info {
	background-color: #00aeef;
	color: white;
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
	.slider-wrapper
	{
		position: relative;
		width: 100%;
		height: 170px;
	}

	.slider-panel
	{
		position: relative;
		width: 100%;
		height: 60vh!important;
		min-height: 0;
		
		background: rgba($color-primary, 1);
	}
}

@media (max-width: 767px) and (max-height: 1080px) {
	.slider-wrapper
	{
		height: 170px;
	}
	
	.slider-panel
	{
		height: 500px!important;
	}
}